import React, {useEffect, useState} from 'react';
import {Box, Flex, Link, Text} from '@chakra-ui/react';
import {Product} from '../../shared/entities/Product/Product';
import {MainButton} from "../../components/Buttons/MainButton";
import {URLPaths} from "../../config/application/URLPaths";
import {usePrivileges} from "../../hooks/PrivilegesProvider";
import {useNavigate} from "react-router";
import EditButtons from "../../components/Buttons/EditButtons";
import {ProductTypes} from "../../common/constants";
import DragAndDropBox from "../../components/DragAndDrop/DragAndDropBox";
import QuantityInput from "../../components/Inputs/QuantityInput";
import {ProductCostCalculateUtils} from "../../shared/utilities/ProductCostCalculateUtils";
import {useAppSelector} from "../../redux/Store";
import DiscountComponent from "./Discount/DiscountComponent";
import {SelectModal} from "../../redux/Modal/ModalReducer";
import {ModalService} from "../../service/Modal/ModalService";
import {useDispatch} from "react-redux";
import ImageCustom from "../../components/Image/ImageCustom";

type ProductItemProps = {
    product: Product;
    singleHide?: boolean;
    onAddToCart?: (product: Product, quantity: number) => void;
    height?: string;
    width?: string;
    isMobile?: boolean;
};

const ProductItem: React.FC<ProductItemProps> = ({
                                                     product, onAddToCart,
                                                     singleHide = true,
                                                     height,
                                                     width = "100%",
                                                     isMobile = false
                                                 }) => {
    const {priv} = usePrivileges();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const authDealership = useAppSelector(state => state.AuthReducer.dealership);
    const calculatedPrice = ProductCostCalculateUtils.calculatePrice(product, authDealership ? authDealership : undefined);
    const modalService = new ModalService(dispatch);
    const [reset, setReset] = useState<boolean>(false);

    useEffect(() => {
        setTotal(Number((calculatedPrice * quantity).toFixed(4)));
    }, [product.price, quantity]);

    function getLabel(product: Product): string {
        switch (product.type) {
            case ProductTypes.CUSTOM:
                return "ADD-CUSTOM"
            case ProductTypes.REORDER:
                return "RE-ORDER"
            case ProductTypes.PRODUCT:
                return "Add to Cart"
            default:
                return "UNDEF"
        }
    }

    return (
        <Flex maxWidth={width} height={height}>
            {priv?.PRODUCT_EDIT &&
                <DragAndDropBox item={product} width={"5%"} orderByZone={true} height={`${height}px`}/>
            }
            <DragAndDropBox item={product} isDraggable={priv?.PRODUCT_EDIT}>
                {singleHide && (
                    <Box border="1px" borderColor={"gray.200"} borderRadius={"md"}>
                        <Link href={`${URLPaths.PRODUCTS.link}${product.id}`}>
                            <ImageCustom elem={product} sizeInPercent={isMobile ? 75 : 100} showOnHover={false}
                                         isMobail={isMobile}
                                         sizeBackend={"COMMON"}
                                         hideArrows={false}/>
                        </Link>
                    </Box>
                )}
                <Text fontWeight={"md"} fontSize={"14px"} pt={4}>
                    {product.name.replace("(# ", "(#")}
                </Text>
                {product.description &&
                    <Text color="primary.text" fontWeight={"sm"} pt={1} fontSize={"14px"}>
                        {product.description}
                    </Text>
                }
                <Box display="flex" flexDir="row">
                    {ProductTypes.CUSTOM === product.type &&
                        <>
                            <Text fontSize={"14px"}>
                                {'This product is custom and will be billed on a separate invoice.'}
                            </Text>
                        </>
                    }
                    {ProductTypes.REORDER === product.type &&
                        <>
                            <Text fontSize={"14px"}>
                                {'This product is custom and will be billed on a separate invoice.'}
                            </Text>
                        </>
                    }
                </Box>
                {ProductTypes.PRODUCT === product.type &&
                    <Box pt={1}>
                        <DiscountComponent elem={product} textAlign={"left"} showDiscount={true}
                                           selectedDealer={authDealership ? authDealership : undefined}/>
                    </Box>
                }
                <Box display="flex" alignItems="center" pt={1}>
                    {ProductTypes.OPTIONS !== product.type && ProductTypes.SUBCATEGORY !== product.type &&
                        (
                            <Flex width={isMobile ? "100%" : "unset"} flexDir={isMobile ? "column" : "row"}>
                                <Box pb={isMobile ? 2 : "unset"} pr={!isMobile ? 1 : "unset"}>
                                    <QuantityInput item={product} reset={reset} width={isMobile ? "100%" : 97}
                                                   isMobile={isMobile}
                                                   onQuantityChange={(updatedItem) => {
                                                       setQuantity(updatedItem.quantity)
                                                       setReset(false);
                                                   }}/>
                                </Box>


                                <MainButton
                                    isDisabled={quantity === 0}
                                    onClick={() => {
                                        modalService.setModal({
                                            selModel: SelectModal.ADD_PRODUCT,
                                            product: product,
                                            quantity: quantity
                                        })
                                        setReset(true);
                                    }}>
                                    {getLabel(product)}
                                </MainButton>
                            </Flex>
                        )
                    }

                    {ProductTypes.OPTIONS === product.type &&
                        <MainButton width={isMobile ? "100%" : "unset"}
                                    onClick={() => navigate(`${URLPaths.PRODUCTS.link}${product.id}`)}>
                            OPTIONS
                        </MainButton>
                    }
                    <EditButtons riseLevelProduct={priv?.PRODUCT_EDIT} item={product}/>
                </Box>
                {(ProductTypes.OPTIONS !== product.type &&
                        ProductTypes.SUBCATEGORY !== product.type &&
                        ProductTypes.CUSTOM !== product.type &&
                        ProductTypes.REORDER !== product.type
                    ) &&
                    <Text fontSize="14px" color={"primary.text"} pt={1}>
                        <span style={{fontWeight: "400", paddingRight: 5}}> {"TOTAL"}</span>
                        <span style={{fontWeight: "600"}}>{`$${total}`}</span>
                    </Text>
                }
            </DragAndDropBox>
        </Flex>
    );
};

export default ProductItem;
