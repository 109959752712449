import {ChakraProps, Flex, Text} from "@chakra-ui/react";
import React, {FC} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import {PreventNavigationModal} from "../../shared/utilities/PreventNavigationModal";
import {BreadcrumbsData} from "../../shared/entities/Breadcrumbs/BreadCrumbsData";
import {Icon} from "@chakra-ui/icons";
import {
    FaAngleRight
} from "react-icons/all";


/**
 * Компонент бредкрабмс - это стандартные хлебные крошки
 * на всех страницах портала
 *
 */
export const Breadcrumbs: FC = () => {
    const savedBreadItems = sessionStorage.getItem("breadcrumbsData");
    let breadcrumbsData: Array<BreadcrumbsData> = [];
    if (savedBreadItems) {
        breadcrumbsData = JSON.parse(savedBreadItems);
    }
    const location = useLocation()
    const dispath = useDispatch()
    const cannotNavigateWithoutModal = location.pathname.includes("edit") || location.pathname.includes("create") || location.pathname.includes("connect") || location.pathname.includes("copy")

    const navigate = useNavigate()
    const BreadcrumbWrapperStyleConfig: ChakraProps = {fontSize: "sm"}
    const NavigationLogic = (canNavigate: boolean, navFunc: Function) => {
        !canNavigate ? navFunc() : PreventNavigationModal(dispath, navFunc)
    }

    function truncateText(text: string, maxLength: number) {
        if (text.length <= maxLength) {
            return text;
        } else {
            return '...' + text.substring(text.length - maxLength);
        }
    }

    const Links = breadcrumbsData?.map((elem: BreadcrumbsData, idx: number) => {
        const isLast = breadcrumbsData.length - 1 === idx;
        const truncatedText = truncateText(elem.text, 50); // Обрезаем текст и начинаем с трех точек

        return (
            <Flex key={idx} alignItems={"center"}>
                {!isLast ? (
                    <Text
                        fontSize={14}
                        fontWeight={"sm"}
                        color="gray.400"
                        cursor="pointer"
                        _hover={{color: "gray.800", textDecoration: "underline"}}
                        children={truncatedText}
                        onClick={() =>
                            NavigationLogic(
                                cannotNavigateWithoutModal,
                                () => navigate(elem.link)
                            )
                        }
                    />
                ) : (
                    <Text
                        fontSize={14}
                        fontWeight={"md"}
                        children={truncatedText}
                        color="gray.800"
                    />
                )}
                {!isLast && (
                    <Icon as={FaAngleRight} px={2} fontSize={24} color={"gray.800"} cursor={"pointer"}/>
                )}
            </Flex>
        );
    });

    return (
        <>
            {Links?.length > 0 && <Flex {...BreadcrumbWrapperStyleConfig} wrap="wrap">
                {Links}
            </Flex>}
        </>
    )
}
