import React, { FC } from "react";
import { Helmet } from "react-helmet";
import {WebPUtils} from "../../shared/utilities/WebPUtils";

interface SchemaOrgProps {
    isGeneralPage: boolean;
    isProductGroup: boolean;
    parentProduct?: any;
    url: string;
}

const SchemaOrg: FC<SchemaOrgProps> = ({ isGeneralPage, isProductGroup, parentProduct, url }) => {
    const schemaData = {
        "@context": "https://schema.org",
        "@type": isGeneralPage ? "WebPage" : (isProductGroup ? "ProductCollection" : "Product"),
        "name": isGeneralPage ? "Home Page" : parentProduct?.name || "Product",
        "description": isGeneralPage
            ? "AK Dealer Services provides quality auto parts and services for dealers and customers. Explore our range of products and solutions."
            : parentProduct?.description || "Product description",
        "url": url,
        ...(isGeneralPage || isProductGroup ? {} : {
            "sku": String(parentProduct?.id || "SKU"),
            "offers": {
                "@type": "Offer",
                "priceCurrency": "USD",
                "price": parentProduct?.price || "0.00",
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock",
                "url": url
            },
            image: parentProduct?.images?.length
                ? parentProduct.images.map((img: { uuid: string }) => `${WebPUtils.getProxy()}/file/${img.uuid}?size=GENERAL`)
                : ["https://example.com/default-image1.jpg", "https://example.com/default-image2.jpg"]
        })
    };

    const schemaDataHome = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "WebPage",
                "@id": `${window.location.origin}/`,
                "url": `${window.location.origin}/`,
                "name": "AK Dealer Services - Quality Auto Parts & Services",
                "isPartOf": {
                    "@id": `${window.location.origin}/#website`
                },
                "about": {
                    "@id": `${window.location.origin}/#organization`
                },
                "primaryImageOfPage": {
                    "@id": `${window.location.origin}/#primaryimage`
                },
                "image": {
                    "@id": `${window.location.origin}/#primaryimage`
                },
                "thumbnailUrl": `${window.location.origin}/favicon.ico`,
                "datePublished": "2023-07-01T00:00:00+00:00",
                "dateModified": "2024-09-21T00:00:00+00:00",
                "description": "AK Dealer Services provides quality auto parts and services for dealers and customers. Explore our range of products and solutions.",
                "breadcrumb": {
                    "@id": `${window.location.origin}/#breadcrumb`
                },
                "inLanguage": "en-US",
                "potentialAction": [
                    {
                        "@type": "ReadAction",
                        "target": [
                            `${window.location.origin}/`
                        ]
                    }
                ]
            },
            {
                "@type": "ImageObject",
                "inLanguage": "en-US",
                "@id": `${window.location.origin}/#primaryimage`,
                "url": `${window.location.origin}/favicon.ico`,
                "contentUrl": `${window.location.origin}/favicon.ico`,
                "width": 32,
                "height": 32
            },
            {
                "@type": "BreadcrumbList",
                "@id": `${window.location.origin}/#breadcrumb`,
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "position": 1,
                        "name": "Home"
                    }
                ]
            },
            {
                "@type": "WebSite",
                "@id": `${window.location.origin}/#website`,
                "url": `${window.location.origin}/`,
                "name": "AK Dealer Services",
                "description": "Quality Auto Parts & Services",
                "publisher": {
                    "@id": `${window.location.origin}/#organization`
                },
                "potentialAction": [
                    {
                        "@type": "SearchAction",
                        "target": {
                            "@type": "EntryPoint",
                            "urlTemplate": `${window.location.origin}/?s={search_term_string}`
                        },
                        "query-input": "required name=search_term_string"
                    }
                ],
                "inLanguage": "en-US"
            },
            {
                "@type": [
                    "Organization",
                    "AutoPartsStore"
                ],
                "@id": `${window.location.origin}/#organization`,
                "name": "AK Dealer Services",
                "url": `${window.location.origin}/`,
                "logo": {
                    "@type": "ImageObject",
                    "inLanguage": "en-US",
                    "@id": `${window.location.origin}/#/schema/logo/image/`,
                    "url": `${window.location.origin}/favicon.ico`,
                    "contentUrl": `${window.location.origin}/favicon.ico`,
                    "width": 32,
                    "height": 32,
                    "caption": "AK Dealer Services"
                },
                "image": {
                    "@id": `${window.location.origin}/#/schema/logo/image/`
                },
                "sameAs": [
                    "https://www.facebook.com/AKDealerServices/",
                    "https://www.linkedin.com/company/ak-dealer-services/"
                ],
                "slogan": "Quality Auto Parts & Services",
                "description": "AK Dealer Services provides quality auto parts and services for dealers and customers."
            }
        ]
    };

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(isGeneralPage ? schemaDataHome : schemaData)}
            </script>
        </Helmet>
    );
};

export default SchemaOrg;
