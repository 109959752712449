import {Box, Flex, Link as ChakraLink, Text} from "@chakra-ui/react";
import {Link as ReactRouterLink} from 'react-router-dom'
import React from "react";
import {Product} from "../../../shared/entities/Product/Product";
import {URLPaths} from "../../../config/application/URLPaths";
import {usePrivileges} from "../../../hooks/PrivilegesProvider";
import EditButtons from "../../../components/Buttons/EditButtons";
import ImageCustom from "../../../components/Image/ImageCustom";
import DragAndDropBox from "../../../components/DragAndDrop/DragAndDropBox";
import ProductVideo from "../ProductVideo";
import {generalBox} from "../../../theme/foundations/breakpoints";
import {CardBody} from "../../../components/Card/CardBody";

interface CategorySectionProps {
    product: Product;
    height?: string;
    width?: string;
    divider: number;
    isMobile?: boolean;
}

const CategoryGeneralItem: React.FC<CategorySectionProps> = ({
                                                                 product,
                                                                 height,
                                                                 width = "100%",
                                                                 divider = 1,
                                                                 isMobile = false
                                                             }) => {
    const {priv} = usePrivileges();
    return (
        <Flex w={width} height={height}>
            {priv?.PRODUCT_EDIT &&
                <DragAndDropBox item={product} width={"18px"} orderByZone={true}/>
            }
            <CardBody>
                <DragAndDropBox item={product} isDraggable={priv?.PRODUCT_EDIT}>
                    {product.id !== -1 && <EditButtons item={product}/>}
                    <ChakraLink to={`${URLPaths.PRODUCTS.link}${product.id}`} as={ReactRouterLink}>
                        <Flex p={6} height={generalBox.category.heightTitle}>
                            <Flex alignItems="center">
                                <Text
                                    fontSize="2rem" fontWeight={"md"}
                                    letterSpacing={"-0.04rem"}
                                    lineHeight={"2rem"}
                                    cursor={"pointer"}
                                >
                                    {product.name}
                                </Text>
                                <Box pl={3}>
                                    <ProductVideo video={product.video[0]}/>
                                </Box>
                            </Flex>
                        </Flex>
                        <ImageCustom elem={product} currentSize={"general"} sizeBackend={"GENERAL"}
                                     isMobail={isMobile}
                                     noHandleLink={product.name === 'PERMAPLATE'} sizeInPercent={100 / divider}/>
                    </ChakraLink>
                </DragAndDropBox>
            </CardBody>
        </Flex>
    );
};

export default CategoryGeneralItem;
