import React from 'react';
import {Link as ReactRouterLink} from 'react-router-dom'
import {Box, Flex, Link as ChakraLink, Text} from '@chakra-ui/react';
import {Product} from "../../../shared/entities/Product/Product";
import {usePrivileges} from "../../../hooks/PrivilegesProvider";
import {CardBody} from "../../../components/Card/CardBody";
import {URLPaths} from "../../../config/application/URLPaths";
import DragAndDropBox from "../../../components/DragAndDrop/DragAndDropBox";
import EditButtons from "../../../components/Buttons/EditButtons";

type NewProductComponentProps = {
    product: Product;
    height?: string;
};

const NeedMoreProduct: React.FC<NewProductComponentProps> = ({product, height}) => {
    const {priv} = usePrivileges();

    return (
        <Flex height={height}>
            {priv?.PRODUCT_EDIT &&
                <DragAndDropBox item={product} width={"5%"} orderByZone={true} height={`${height}px`}/>
            }
            <CardBody border={"unset"} width={priv?.PRODUCT_EDIT ? "95%" : "100%"}>
                <DragAndDropBox item={product} isDraggable={priv?.PRODUCT_EDIT}>
                    <EditButtons item={product}/>
                    <Flex height={"70%"} alignItems="center">
                        <ChakraLink to={`${URLPaths.ORDER_BY_CATALOG.link}/${product.description}`}
                                    as={ReactRouterLink}>
                            <Flex backgroundColor="primary.gray.light"
                                  alignItems="center"
                                  justifyContent="center"
                                  mi="100%"
                                  width={"100%"}
                                  height="80px"
                                  cursor={"pointer"}
                                  border="1px" borderColor={"gray.200"} borderRadius={"md"}
                            >
                                <Box textAlign={"center"} w={"80%"}>
                                    <Text fontSize={16} fontWeight={"md"}>
                                        {product.name}
                                    </Text>
                                </Box>
                            </Flex>
                        </ChakraLink>
                    </Flex>
                </DragAndDropBox>
            </CardBody>
        </Flex>
    );
};

export default NeedMoreProduct;